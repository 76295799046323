<template>
  <div :class="classNames.root">
    <LucideInfo :class="classNames.icon" />
    <UiSkeleton :classes="classNames.skeleton" :loading="isFetching">
      <span>{{ t("returns_from", { toDate: returnDates.to, fromDate: returnDates.from }) }} </span>
    </UiSkeleton>
  </div>
</template>

<script setup lang="ts">
const { data, isFetching } = useGeneralParameters()
const dayjs = useDayjs()
const { t } = useI18n({ useScope: "local" })

const props = defineProps<{
  classes?: Partial<{
    root: ClassValue
    icon: ClassValue
    skeleton: { line: ClassValue; container: ClassValue }
  }>
}>()

const classNames = computed(() => ({
  root: cn(
    "min-md:mt-6 inline-flex items-center gap-1 text-sm text-black/30 md:order-2 md:mb-4",
    props.classes?.root
  ),
  icon: cn("flex h-3 w-3 items-center rounded-full p-0 font-light", props.classes?.icon),
  skeleton: {
    container: cn("flex h-5 justify-center", props.classes?.skeleton?.container),
    line: cn("h-3 w-60 rounded-full", props.classes?.skeleton?.line),
  },
}))

const returnDates = computed(() => {
  return {
    to:
      !data.value?.stockRankingDate && isFetching.value
        ? t("loading")
        : dayjs.unix(data.value?.stockRankingDate!).format("MMMM DD, YYYY"),
    from: dayjs("2022-08-25").format("MMMM DD, YYYY"),
  }
})
</script>

<i18n lang="json">
{
  "he": {
    "returns_from": "תשואות מ{fromDate} עד {toDate}",
    "loading": "טוען..."
  },
  "en": {
    "returns_from": "Returns from {fromDate} to {toDate}",
    "loading": "Loading..."
  }
}
</i18n>
